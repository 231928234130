// This is for scrollbar style
// include scroll-container class to your parent scrollable div
.scroll-container::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
}

.scroll-container::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #c0c8ef // Put your color
}

.scroll-container::-webkit-scrollbar-button,
.scroll-container::-webkit-scrollbar-track,
.scroll-container::-webkit-scrollbar-track-piece,
.scroll-container::-webkit-scrollbar-corner,
.scroll-container::-webkit-resizer {
    display: none;
}